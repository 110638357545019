import React from "react";
import { Link } from "gatsby";

import logo from "../img/logo.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
          <img
            src={logo}
            alt="LastDo"
            style={{ width: "14em", height: "10em" }}
          />
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/tips">
                        Tips and tricks
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/privacy">
                        Privacy policy
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/terms">
                        Terms and conditions
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/developer">
                        Information about the developer
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <div className="flex">
                    <a href="http://play.google.com/store/apps/details?id=com.lastdo.app">
                      <img
                        alt="Get it on Google Play"
                        src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                      />
                      <div className="label">
                      Free Version
                      </div>
                    </a>
                    Google Play and the Google Play logo are trademarks of
                    Google LLC.
                    <a href="http://play.google.com/store/apps/details?id=com.lastdo.pro">
                      <img
                        alt="Get it on Google Play"
                        src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png"
                      />
                      <div className="label">
                      Pro Version
                      </div>
                    </a>
                  </div>
                </section>
              </div>
              <div className="column is-4 social">
                <a href="https://t.me/lastdo">Telegram</a>
                <a href="https://www.reddit.com/r/LastDo/">Reddit</a>
                <a href="https://www.xda-developers.com/">XDA</a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
